
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultLoader from '@/components/ui/loader/DefaultLoader.vue';
import ChevronLeftIcon from '@/components/ui/icon/ChevronLeftIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton.vue';
import TheLogo from '@/components/catalog/TheLogo.vue';
import ScrollMenuItem from '@/components/ui/menu/ScrollMenu/ScrollMenuItem.vue';
import PhotoIcon from '@/components/ui/icon/PhotoIcon.vue';

export default defineComponent({
  name: 'TheProduct',
  components: {
    PhotoIcon,
    DefaultLoader,
    ChevronLeftIcon,
    IconButton,
    TheLogo,
    ScrollMenuItem,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      showFullDescription: false,
      showFullInstruction: false,
      currentActiveTab: '1',
      tabList: [
        {
          id: '1',
          text: 'Описание',
        },
        {
          id: '2',
          text: 'Инструкция',
        },
        {
          id: '3',
          text: 'Цены',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      data: 'product/one/data',
      currentProductId: 'catalog/list/currentProductId',
    }),
    images() {
      return this.data.Images;
    },
    shortDescription() {
      return this.showFullDescription ? this.data.Description : `${this.data.Description.slice(0, 300)}...`;
    },
    shortInstruction() {
      return this.showFullInstruction ? this.data.Instruction : `${this.data.Instruction.slice(0, 300)}...`;
    },
    descriptionButton() {
      return !this.showFullDescription ? 'Показать больше.' : 'Скрыть.';
    },
    instructionButton() {
      return !this.showFullInstruction ? 'Показать больше.' : 'Скрыть.';
    },
    sliderWidth() {
      return this.images.length * window.innerWidth;
    },
  },
  methods: {
    ...mapActions({
      loadData: 'product/one/loadData',
    }),
    showDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    showInstruction() {
      this.showFullInstruction = !this.showFullInstruction;
    },
    updateTabHandler(id: string) {
      this.currentActiveTab = id;
    },
    closeProduct() {
      this.$emit('closeProduct');
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadData(this.currentProductId);

    this.isLoading = false;
  },
});
