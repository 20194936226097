
import { defineComponent, PropType } from 'vue';
import TheLogo from '@/components/catalog/TheLogo.vue';
import ScrollMenu, { ScrollMenuType } from '@/components/ui/menu/ScrollMenu/ScrollMenu.vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'TheHeader',
  emits: ['updateCatalog'],
  components: {
    TheLogo,
    ScrollMenu,
  },
  props: {
    menuItems: {
      type: Array as PropType<ScrollMenuType[]>,
      default: () => ([]),
    },
    currentCatalogId: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setOffset: 'product/list/setOffset',
    }),
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.$emit('updateCatalog', catalogId, next);
      this.setOffset(0);
    },
  },
});
