
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ScrollMenuItem',
  emits: ['updateCatalog'],
  props: {
    text: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectCatalog(event: Event) {
      if (!this.isActive && !this.isLoading) {
        this.$emit('updateCatalog', this.id, () => {
          const element = event.target as HTMLElement;
          element.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth',
          });
        });
      }
    },
  },
});
