import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export interface ErrorResponse {
  name: string,
  message: string,
  code: number,
  status: number,
}

export interface LinkParam {
  href: string,
}

export interface LinksParams {
  self?: LinkParam,
  prev?: LinkParam,
  next?: LinkParam,
  last?: LinkParam,
  first?: LinkParam,
}

export interface MetaParams {
  currentPage?: number | null,
  pageCount?: number | null,
  perPage?: number | null,
  totalCount?: number | null,
}

export interface ResponseOfRecords<T> {
  Items: T,
  status?: number,
  _links?: LinksParams,
  _meta?: MetaParams,
}

export interface DetailResponse<T> {
  data: T,
}

export interface AxiosCallResponse<T> {
  data?: T,
  error?: AxiosError<ErrorResponse | null>,
}

export interface SortParams {
  sort?: string | null,
}

export interface FilterParams<T = object> {
  filter?: T,
}

export interface PaginationParams {
  page?: number | null,
  'per-page'?: number | null,
}

export interface QueryParamsForRecords extends SortParams, FilterParams, PaginationParams {
  fields?: string,
}

export class BaseService {
  protected axiosInstance: AxiosInstance | undefined;

  constructor(resource: string, token: string) {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}/${resource}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  protected async axiosCall<T>(config: AxiosRequestConfig): Promise<AxiosCallResponse<T>> {
    if (!this.axiosInstance) {
      throw new Error('axiosInstance not set');
    }

    try {
      const { data } = await this.axiosInstance.request(config);

      return { data };
    } catch (err: unknown) {
      const error = err as AxiosError<ErrorResponse | null>;

      console.log(error);

      return { error };
    }
  }
}
