
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PhotoIcon',
  props: {
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
  },
});
