import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee94cb26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-list" }
const _hoisted_2 = {
  key: 0,
  class: "space-y-[12px] ml-[]"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "absolute top-[50%] left-[50%] translate-x-[-50%]"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "fixed top-[152px] left-0 h-[54px] bg-white shadow-md w-full text-[20px] font-bold flex items-center"
}
const _hoisted_8 = {
  id: "observer",
  class: "h-[1px]"
}
const _hoisted_9 = {
  key: 3,
  class: "load-button"
}
const _hoisted_10 = {
  key: 0,
  class: "fixed top-0 left-0 right-0 bottom-0 bg-white z-[10] overflow-scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_left_icon = _resolveComponent("chevron-left-icon")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_product_object = _resolveComponent("product-object")!
  const _component_button_loader = _resolveComponent("button-loader")!
  const _component_the_product = _resolveComponent("the-product")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.currentCatalog.Items?.length > 0 && !_ctx.isOpenCatalog)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentCatalog.Items, (catalog) => {
              return (_openBlock(), _createElementBlock("div", {
                key: catalog.GUID,
                class: "catalog-item",
                onClick: ($event: any) => (_ctx.selectCurrentCatalog(catalog.GUID))
              }, [
                _createElementVNode("div", null, _toDisplayString(catalog.Name), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_chevron_left_icon, { class: "rotate-180" })
                ])
              ], 8, _hoisted_3))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_default_loader)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["space-y-[24px]", {'mt-[54px]': _ctx.isOpenCatalog}])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.GUID
                      }, [
                        _createVNode(_component_product_object, {
                          data: item,
                          onClick: ($event: any) => (_ctx.openProduct(item.GUID))
                        }, null, 8, ["data", "onClick"])
                      ]))
                    }), 128))
                  ], 2)
                ]))
          ])),
      (_ctx.isOpenCatalog)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "w-[40px] h-[40px] flex items-center justify-center mx-[6px]",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeCatalog && _ctx.closeCatalog(...args)))
            }, [
              _createVNode(_component_chevron_left_icon)
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.currentCatalogName), 1)
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_8, null, 512), [
        [_vShow, !_ctx.isLoadingPagination]
      ]),
      (_ctx.isLoadingPagination)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_button_loader, { class: "scale-50" })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isOpenProduct)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_the_product, { onCloseProduct: _ctx.closeProductHandler }, null, 8, ["onCloseProduct"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}