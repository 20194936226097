import en from '@/locales/en';
import ru from '@/locales/ru';
import { i18nSchema } from '@/locales/schema';
import {
  createI18n,
  I18n,
  LocaleMessages,
} from 'vue-i18n';

const messages: LocaleMessages<i18nSchema> = {
  en,
  ru,
};

const i18n: I18n<LocaleMessages<i18nSchema>> = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
