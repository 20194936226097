
import { defineComponent, PropType } from 'vue';
import ScrollMenuItem from '@/components/ui/menu/ScrollMenu/ScrollMenuItem.vue';

export interface ScrollMenuType {
  name: string,
  link: string,
}

export default defineComponent({
  name: 'ScrollMenu',
  emits: ['updateCatalog'],
  components: {
    ScrollMenuItem,
  },
  props: {
    items: {
      type: Array as PropType<ScrollMenuType[]>,
      default: () => ([]),
    },
    currentCatalogId: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.$emit('updateCatalog', catalogId, next);
    },
  },
});
