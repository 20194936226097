
import { defineComponent, PropType } from 'vue';
import PhotoIcon from '@/components/ui/icon/PhotoIcon.vue';
import { mapGetters } from 'vuex';
import { ProductItem } from '@/api/products';

export default defineComponent({
  name: 'ProductObject',
  components: {
    PhotoIcon,
  },
  props: {
    data: {
      type: Object as PropType<ProductItem>,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      storeCurrentProductId: 'catalog/list/currentProductId',
    }),
  },
});
