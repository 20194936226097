import { Module } from 'vuex';
import { StateType } from '@/store';
import CatalogService, { CatalogItem, CatalogResponseType } from '@/api/catalog';
import { AxiosCallResponse } from '@/api/base';

export interface CatalogListStateType {
  items: CatalogItem[],
  currentActiveCatalogId: string | null,
  currentProductId: string | null,
}

const initialState = {
  items: [],
  currentActiveCatalogId: null,
  currentProductId: null,
};

const roleListModule: Module<CatalogListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): CatalogItem[] => state.items,
    currentActiveCatalogId: (state): string | null => state.currentActiveCatalogId,
    currentProductId: (state): string | null => state.currentProductId,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setCurrentActiveCatalogId(state, id) {
      state.currentActiveCatalogId = id;
    },
    setCurrentProductId(state, id) {
      state.currentProductId = id;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }) {
      const api: CatalogService = rootGetters.$api.catalog;

      const response: AxiosCallResponse<CatalogResponseType> = await api.list();

      commit('setItems', response.data?.Items ?? initialState.items);
    },
    setCatalogId({ commit }, id: string) {
      commit('setCurrentActiveCatalogId', id);
    },
    setProductId({ commit }, id: string) {
      commit('setCurrentProductId', id);
    },
  },
};

export default roleListModule;
