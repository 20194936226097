
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { CatalogItem } from '@/api/catalog';
import ChevronLeftIcon from '@/components/ui/icon/ChevronLeftIcon.vue';
import ProductObject from '@/components/catalog/ProductObject.vue';
import DefaultLoader from '@/components/ui/loader/DefaultLoader.vue';
import TheProduct from '@/views/product/TheProduct.vue';
import ButtonLoader from '@/components/ui/loader/ButtonLoader.vue';

export default defineComponent({
  name: 'ProductList',
  emits: ['updateIsLoading', 'updateIsPaginationLoading'],
  components: {
    ButtonLoader,
    TheProduct,
    DefaultLoader,
    ProductObject,
    ChevronLeftIcon,
  },
  data() {
    return {
      isLoading: true,
      isLoadingPagination: false,
      isOpenCatalog: false,
      isOpenProduct: false,
      localCurrentCatalogId: null,
      lastOffset: 0,
    };
  },
  props: {
    currentCatalog: {
      type: Object as PropType<CatalogItem>,
    },
  },
  computed: {
    ...mapGetters({
      offset: 'product/list/offset',
      products: 'product/list/items',
    }),
    currentCatalogName() {
      return this.currentCatalog.Items?.find(
        (item: CatalogItem) => item.GUID === this.localCurrentCatalogId,
      )?.Name;
    },
  },
  methods: {
    ...mapActions({
      setOffset: 'product/list/setOffset',
      setCurrentProductId: 'catalog/list/setProductId',
      loadProducts: 'product/list/loadItems',
    }),
    async selectCurrentCatalog(catalogId: string) {
      this.isLoading = true;
      this.$emit('updateIsLoading', this.isLoading);

      this.isOpenCatalog = true;
      this.localCurrentCatalogId = catalogId;
      await this.loadProducts(catalogId);

      this.isLoading = false;
      this.$emit('updateIsLoading', this.isLoading);
    },
    closeCatalog() {
      this.isOpenCatalog = false;
      this.setOffset(0);
    },
    openProduct(id: string) {
      this.setCurrentProductId(id);
      this.isOpenProduct = true;
      document.body.classList.add('scroll-block');
    },
    closeProductHandler() {
      this.setCurrentProductId(null);
      this.isOpenProduct = false;
      document.body.classList.remove('scroll-block');
    },
    async scrollHandler() {
      if (this.localCurrentCatalogId
          && this.products.length > 0
          && !this.isLoadingPagination
          && this.lastOffset !== this.offset
      ) {
        this.isLoadingPagination = true;
        this.$emit('updateIsPaginationLoading', this.isLoadingPagination);
        this.lastOffset = this.offset;

        await this.loadProducts(this.localCurrentCatalogId);

        this.isLoadingPagination = false;
        this.$emit('updateIsPaginationLoading', this.isLoadingPagination);
      }
    },
  },
  watch: {
    async currentCatalog(catalog: CatalogItem) {
      if (catalog.GUID && catalog.GUID !== this.localCurrentCatalogId) {
        this.isLoading = true;
        this.$emit('updateIsLoading', this.isLoading);

        this.isOpenCatalog = false;
        this.lastOffset = 0;
        this.localCurrentCatalogId = catalog.GUID;

        if (catalog.Items?.length <= 0) {
          await this.loadProducts(catalog.GUID);
        }

        this.isLoading = false;
        this.$emit('updateIsLoading', this.isLoading);
      }
    },
  },
  mounted() {
    const observer = new IntersectionObserver(async () => {
      await this.scrollHandler();
    });

    const target = document.getElementById('observer') as HTMLElement;

    observer.observe(target);
  },
  directives: {
    scroll: {
      mounted(el, binding) {
        window.addEventListener('scroll', binding.value);
      },
    },
  },
});
