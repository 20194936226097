export default {
  common: {
    yes: 'Yes',
    no: 'No',
  },
  buttons: {
    add: 'Add',
    cancel: 'Cancel',
    create: 'Create',
    reset: 'Reset',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
  },
  title: 'Exchange with МОЕХ',
  test: {
    title: 'Test',
  },
};
