import { Module } from 'vuex';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import ProductService, { ProductInfoItem } from '@/api/products';

export interface ProductOneStateType {
  data: ProductInfoItem | null,
}

const initialState = {
  data: null,
};

const productOneModule: Module<ProductOneStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    data: (state): ProductInfoItem | null => state.data,
  },
  mutations: {
    setItems(state, data) {
      state.data = data;
    },
  },
  actions: {
    async loadData({ rootGetters, commit }, currentProductId: string) {
      const api: ProductService = rootGetters.$api.product;

      const response: AxiosCallResponse<ProductInfoItem> = await api.one(currentProductId);

      commit('setItems', response.data ?? initialState.data);
    },
  },
};

export default productOneModule;
