import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6898e584"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scroll_menu_item = _resolveComponent("scroll-menu-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.name
      }, [
        _createVNode(_component_scroll_menu_item, {
          text: item.name,
          "is-active": item.link === _ctx.currentCatalogId,
          id: item.link,
          "is-loading": _ctx.isLoading,
          onUpdateCatalog: _ctx.updateCatalogHandler
        }, null, 8, ["text", "is-active", "id", "is-loading", "onUpdateCatalog"])
      ]))
    }), 128))
  ]))
}