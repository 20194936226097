import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

const NotFoundView = () => import('../views/not-found/NotFound.vue');
const CatalogView = () => import('../views/catalog/TheCatalog.vue');
const ProductView = () => import('../views/product/TheProduct.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: CatalogView,
  },
  {
    path: '/product/:id',
    component: ProductView,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
