import { Module } from 'vuex';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import ProductService, { ProductItem, ProductResponseType } from '@/api/products';
import _ from 'lodash';

export interface ProductListStateType {
  items: ProductItem[],
  offset: number,
}

const initialState = {
  items: [],
  offset: 0,
};

const productListModule: Module<ProductListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): ProductItem[] => state.items,
    offset: (state): number => state.offset,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit, state }, currentCatalogId: string) {
      const api: ProductService = rootGetters.$api.product;

      const response: AxiosCallResponse<ProductResponseType> = await api.list(
        currentCatalogId,
        state.offset,
      );

      const resultData = response.data?.Items ?? initialState.items;

      commit('setItems', _.uniqBy(state.items.concat(resultData), 'GUID'));
      commit('setOffset', state.items.length);
    },
    setOffset({ commit }, offset: number) {
      commit('setItems', []);
      commit('setOffset', offset);
    },
  },
};

export default productListModule;
