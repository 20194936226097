<template>
  <button class="icon-btn relative"
          :class="classList"
          @mousedown="isPress = true"
          @mouseup="isPress=false"
          @mouseenter="showTooltip"
          @mouseleave="hideTooltip"
  >
    <slot/>
    <div v-if="isShowTooltip && title" class="tooltip">
      <span>{{ title }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  data: () => ({
    isPress: false,
    isShowTooltip: false,
    timeoutId: null,
  }),
  props: {
    border: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    classList() {
      return `${(this.isPress ? 'pressed' : '') + (this.border !== '' ? 'bordered' : '')} ${this.border}`;
    },
  },
  methods: {
    showTooltip() {
      this.timeoutId = setTimeout(() => {
        this.isShowTooltip = true;
      }, 600);
    },
    hideTooltip() {
      this.isShowTooltip = false;
      clearTimeout(this.timeoutId);
    },
  },
};
</script>

<style scoped lang="scss">
.icon-btn {
  @apply w-[40px] h-[40px] flex items-center justify-center relative bg-transparent;

  border-radius: 50%;
  overflow: visible;
  color: #64748B;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:disabled {
    opacity: 0.5;
  }

  &.bordered {
    border: 1px solid;
  }
  &.default {
    border-color: #CBD5E1;
  }

  &.primary {
    border-color: #4F46E5;
    background-color: #4F46E5;
  }
}

.icon-btn:hover {
  background: #F3F4F6;
}

.icon-btn.pressed {
  background: #E5E7EB;
}

.tooltip {
  @apply absolute left-[20px] top-[20px];
  @apply text-[11px] p-[8px] mt-[10px] rounded-sm bg-white shadow-md;
  @apply flex items-center justify-center;
}
</style>
