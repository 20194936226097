<template>
  <span class="loader" />
</template>

<style scoped lang="scss">
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: black;
  box-shadow: 32px 0 black, -32px 0 black;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF2, -32px 0 black;
  }
  50% {
    background-color: black;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 black, -32px 0 #FFF2;
  }
}

</style>
<script setup lang="ts">
</script>
