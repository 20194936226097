import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eb688b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "product-card",
  ref: "product"
}
const _hoisted_2 = { class: "image-card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full flex flex-col justify-between" }
const _hoisted_5 = { class: "text-[16px] font-bold" }
const _hoisted_6 = { class: "text-[#7d7f82] text-[12px] leading-3" }
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = { class: "text-[12px] font-bold" }
const _hoisted_9 = { class: "text-[15px] font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_icon = _resolveComponent("photo-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.data.Images?.[0])
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: ('data:image/png;base64,' + _ctx.data.Images[0]),
            class: "h-[136px]",
            alt: ""
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_photo_icon, {
            key: 1,
            width: "40",
            height: "40"
          }))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.Name), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.data.Title), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.data.Volume === '0' ? '' : _ctx.data.Volume), 1),
        _createElementVNode("span", _hoisted_9, " от " + _toDisplayString(_ctx.data.Price) + " ₽ ", 1)
      ])
    ])
  ], 512))
}