import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { CatalogListStateType } from '@/store/modules/catalog/list';

export interface CatalogStateType {
  list: CatalogListStateType,
}

const catalogModule: Module<CatalogStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default catalogModule;
