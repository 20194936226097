import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_loader = _resolveComponent("button-loader")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_product_list = _resolveComponent("product-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_button_loader),
    _createVNode(_component_the_header, {
      "menu-items": _ctx.prepareMenuItems,
      "current-catalog-id": _ctx.currentCatalogId,
      "is-loading": _ctx.isLoading || _ctx.isPaginationLoading,
      onUpdateCatalog: _ctx.updateCatalogHandler
    }, null, 8, ["menu-items", "current-catalog-id", "is-loading", "onUpdateCatalog"]),
    _createVNode(_component_product_list, {
      "current-catalog": _ctx.currentCatalog,
      onUpdateIsLoading: _ctx.updateIsLoadingHandler,
      onUpdateIsPaginationLoading: _ctx.updateIsLoadingPaginationHandler
    }, null, 8, ["current-catalog", "onUpdateIsLoading", "onUpdateIsPaginationLoading"])
  ], 64))
}