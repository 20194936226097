
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TheHeader from '@/components/catalog/TheHeader.vue';
import ProductList from '@/components/catalog/ProductList.vue';
import { CatalogItem } from '@/api/catalog';
import ButtonLoader from '@/components/ui/loader/ButtonLoader.vue';

export default defineComponent({
  name: 'TheCatalog',
  components: {
    ButtonLoader,
    TheHeader,
    ProductList,
  },
  data() {
    return {
      isLoading: false,
      isPaginationLoading: false,
      currentCatalogId: '',
    };
  },
  computed: {
    ...mapGetters({
      catalog: 'catalog/list/items',
      products: 'product/list/items',
      storeCurrentCatalogId: 'catalog/list/currentActiveCatalogId',
    }),
    prepareMenuItems() {
      return this.catalog.map((item: CatalogItem) => ({
        name: item.Name,
        link: item.GUID,
      }));
    },
    currentCatalog() {
      return this.catalog.find((item: CatalogItem) => item.GUID === this.currentCatalogId) ?? {};
    },
  },
  methods: {
    ...mapActions({
      loadCatalog: 'catalog/list/loadItems',
    }),
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.currentCatalogId = catalogId;
      window.scrollTo({ top: 0 });
      setTimeout(() => {
        next();
      }, 100);
    },
    updateIsLoadingHandler(value: boolean) {
      this.isLoading = value;
    },
    updateIsLoadingPaginationHandler(value: boolean) {
      this.isPaginationLoading = value;
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadCatalog();
    window.scrollTo({ top: 0 });

    this.currentCatalogId = this.catalog[0].GUID;

    this.isLoading = false;
  },
});
