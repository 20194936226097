import CatalogService from '@/api/catalog';
import ProductService from '@/api/products';

export interface ApiServices {
  catalog: CatalogService,
  product: ProductService,
}

export default (token: string): ApiServices => ({
  catalog: new CatalogService(token),
  product: new ProductService(token),
});
