import { AxiosCallResponse, BaseService, ResponseOfRecords } from '@/api/base';

export interface CatalogItem {
  Name: string,
  GUID: string,
  Items: CatalogItem[],
}

export type CatalogResponseType = ResponseOfRecords<CatalogItem[]>;

export default class CatalogService extends BaseService {
  constructor(token: string) {
    super('getcatalogs', token);
  }

  list(): Promise<AxiosCallResponse<CatalogResponseType>> {
    return this.axiosCall({ method: 'post', data: {} });
  }
}
