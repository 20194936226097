export default {
  common: {
    yes: 'Да',
    no: 'Нет',
  },
  buttons: {
    add: 'Добавить',
    cancel: 'Отмена',
    create: 'Создать',
    reset: 'Очистить',
    save: 'Сохранить',
    edit: 'Редактировать',
    delete: 'Удалить',
  },
  title: 'Обмен с МОЕХ',
  test: {
    title: 'Тест',
  },
};
