import { createStore, Store } from 'vuex';
import api, { ApiServices } from '@/api';
import catalog, { CatalogStateType } from '@/store/modules/catalog';
import product, { ProductStateType } from '@/store/modules/products';

export interface StateType {
  catalog: CatalogStateType,
  product: ProductStateType,
}

const store: Store<StateType> = createStore({
  getters: {
    $api: (): ApiServices => api(''),
  },
  modules: {
    catalog,
    product,
  },
});

export default store;
