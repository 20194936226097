import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { ProductListStateType } from '@/store/modules/products/list';
import one, { ProductOneStateType } from './products/one';

export interface ProductStateType {
  list: ProductListStateType,
  one: ProductOneStateType,
}

const productModule: Module<ProductStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default productModule;
